import { QuoteSearchResponse } from '../types/Quote';

// const API_BASE_URL = 'http://localhost:8080'; // Adjust this to your API's URL
const API_BASE_URL = 'https://quotes-api-aycvggh7c2gcb9h7.eastus-01.azurewebsites.net/'; // Adjust this to your API's URL

export const searchQuotes = async (query: string, page: number, perPage: number): Promise<QuoteSearchResponse> => {
  const response = await fetch(
    `${API_BASE_URL}/search/quotes?q=${query}&page=${page}&per_page=${perPage}`
  );
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};
