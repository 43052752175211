import React, { useState, useRef } from 'react';
import { Card, Badge, Image, Button } from 'react-bootstrap';
import { Quote } from '../types/Quote';
import { FrownIcon, ChevronDown, ChevronUp, PlayCircle, PauseCircle } from 'lucide-react';
import './QuoteItem.css';

interface QuoteItemProps {
  quote: Quote;
}

const QuoteItem: React.FC<QuoteItemProps> = ({ quote }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedAnalysis, setSelectedAnalysis] = useState<string | null>("serious");
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentAudio, setCurrentAudio] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const handleAnalysisClick = (analysisType: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedAnalysis(analysisType);
    stopAudio();
  };

  const formatDate = (dateString: string) => {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day).toLocaleDateString();
  };

  const getAnalysisContent = () => {
    switch (selectedAnalysis) {
      case 'language':
        return quote.language_analysis;
      case 'humorous':
        return quote.humor_analysis;
      case 'serious':
        return quote.serious_analysis;
      case 'simple':
        return quote.one_word_analysis;
      default:
        return null;
    }
  };

  const getAnalysisSpeechUrl = () => {
    switch (selectedAnalysis) {
      case 'language':
        return quote.language_speech_url;
      case 'humorous':
        return quote.humor_speech_url;
      case 'serious':
        return quote.serious_speech_url;
      case 'simple':
        return quote.one_word_speech_url;
      default:
        return null;
    }
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setIsPlaying(false);
    setCurrentAudio(null);
  };

  const handlePlayPause = (audioType: string) => {
    const audioUrl = audioType === 'summary' ? quote.summary_speech_url : getAnalysisSpeechUrl();
    
    if (audioRef.current) {
      if (isPlaying && currentAudio === audioType) {
        stopAudio();
      } else {
        stopAudio();
        audioRef.current.src = audioUrl || '';
        audioRef.current.play();
        setIsPlaying(true);
        setCurrentAudio(audioType);
      }
    }
  };

  return (
    <div className="quote-item mb-4">
      <Card className="quote-content">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-start mb-2">
            <div className="d-flex">
              {quote.image_url ? (
                <Image
                  src={quote.image_url}
                  alt="Quote thumbnail"
                  className="quote-thumbnail me-3"
                />
              ) : (
                <div className="frowny-face me-3">
                  <FrownIcon size={50} color="#6c757d" />
                </div>
              )}
              <div>
                <Card.Title className="quote-text">{quote.quote_text}</Card.Title>
                <Card.Subtitle className="mb-2 author">{quote.one_word_analysis}</Card.Subtitle>
              </div>
            </div>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? <ChevronUp /> : <ChevronDown />}
            </Button>
          </div>

          <p className='normal-text'>
            <span className="field-label">Date:</span> {formatDate(quote.created_at)}<br />
            <span className="field-label">Source:</span> {quote.source}
          </p>

          {isExpanded && (
            <div className="expanded-content mt-3">
              <p className='normal-text'>
                <span className="field-label">Original Quote:</span><br />
                {quote.quote_text}
              </p>

              <div className="row">
                <div className={quote.image_url ? 'col-6 sm-12' : 'col-12 sm-12'}>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="field-label mb-0">Summary:</p>
                    {quote.summary_speech_url && (
                      <Button 
                        variant="link" 
                        onClick={() => handlePlayPause('summary')}
                        className="p-0"
                      >
                        {isPlaying && currentAudio === 'summary' ? <PauseCircle size={24} /> : <PlayCircle size={24} />}
                      </Button>
                    )}
                  </div>
                  <p className='normal-text'>{quote.summary}</p>
                </div>
                <div className={quote.image_url ? 'col-6 sm-12' : 'col-12 sm-12'}>
                  {quote.image_url && (
                    <Image
                      src={quote.image_url}
                      alt="Quote image"
                      className="quote-image mb-3"
                      fluid
                    />
                  )}
                </div>
              </div>

              <Badge
                bg="primary"
                className="me-2 analysis-badge"
                onClick={(e) => handleAnalysisClick('language', e)}
              >
                Language
              </Badge>
              <Badge
                bg="info"
                className="me-2 analysis-badge"
                onClick={(e) => handleAnalysisClick('humorous', e)}
              >
                Humorous
              </Badge>
              <Badge
                bg="secondary"
                className="analysis-badge"
                onClick={(e) => handleAnalysisClick('serious', e)}
              >
                Serious
              </Badge>
              <Badge
                bg="success"
                className="ms-2 analysis-badge"
                onClick={(e) => handleAnalysisClick('simple', e)}
              >
                Simple
              </Badge>

              {selectedAnalysis && (
                <div className="mt-2 analysis-content">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="quote-text mb-0">
                      <strong>{selectedAnalysis.charAt(0).toUpperCase() + selectedAnalysis.slice(1)} Analysis:</strong>
                    </p>
                    {getAnalysisSpeechUrl() && (
                      <Button 
                        variant="link" 
                        onClick={() => handlePlayPause(selectedAnalysis)}
                        className="p-0"
                      >
                        {isPlaying && currentAudio === selectedAnalysis ? <PauseCircle size={24} /> : <PlayCircle size={24} />}
                      </Button>
                    )}
                  </div>
                  <p className='normal-text'>{getAnalysisContent()}</p>
                </div>
              )}

              <audio 
                ref={audioRef}
                onEnded={stopAudio}
              />
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default QuoteItem;