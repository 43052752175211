import React from "react";
import { BookOpen, Users, Smile, LucideIcon } from "lucide-react";

interface SectionProps {
  icon: React.ReactElement<LucideIcon>;
  title: string;
  content: string;
}

const Section: React.FC<SectionProps> = ({ icon, title, content }) => (
  <div className="flex items-start space-x-4">
    <div className="flex-shrink-0">{icon}</div>
    <div>
      <h2 className="text-xl font-semibold mb-2 text-indigo-600">{title}</h2>
      <p className="text-gray-700">{content}</p>
    </div>
  </div>
);

const About: React.FC = () => (
  <div className="max-w-4xl mx-auto px-4 py-8" style={{textAlign: "left"}}>
    <h1 className="text-4xl font-bold text-center mb-8 text-indigo-600">Quotable Quandaries</h1>
    
    <div className="space-y-6">
      <Section
        icon={<BookOpen className="w-8 h-8 text-indigo-500" />}
        title="Our Mission"
        content="Welcome to Quotable Quandaries—your one-stop destination for unraveling the enigmatic utterances of political figures! We objectively dissect the words of those in the political arena, diving deep into the profound (and sometimes profoundly confusing) statements that shape our world."
      />
      
      <Section
        icon={<Smile className="w-8 h-8 text-indigo-500" />}
        title="Why the Humor?"
        content="Because politics without a sense of humor is like a punctuation mark without a purpose—utterly pointless. We mix serious analysis with wit, exploring the wild jungle of metaphors, idioms, and occasionally misplaced modifiers in political speech."
      />
      
      <Section
        icon={<Users className="w-8 h-8 text-indigo-500" />}
        title="Join Our Tribe"
        content="Whether you're a grammar guru, a political enthusiast, or someone who just enjoys a good laugh at a well-placed pun, you've found your tribe. Join us as we navigate the intricate labyrinth of political quotations—seriously, humorously, and with a keen eye for the quirks of English."
      />
    </div>
    
    <p className="mt-8 text-center text-lg font-semibold text-gray-600">
      Prepare to see quotes in a whole new light. And remember: in the world of politics, the devil is in the details—and sometimes, in the dangling participles.
    </p>
  </div>
);

export default About;