import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { Search } from 'lucide-react';
import QuoteItem from './QuoteItem';
import { QuoteSearchResponse } from '../types/Quote';
import { searchQuotes } from '../services/api';

const QuoteList: React.FC = () => {
  const [quoteData, setQuoteData] = useState<QuoteSearchResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentSearch, setCurrentSearch] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastQuoteElementRef = useCallback((node: HTMLElement | null) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const fetchQuotes = async (term: string, pageNum: number) => {
    try {
      setLoading(true);
      const result = await searchQuotes(term, pageNum, 20);
console.log(result);
      setQuoteData(prevData => 
        pageNum === 1 ? result : {
          ...result,
          items: [...(prevData?.items || []), ...result.items]
        }
      );
      setHasMore(result.items.length > 0);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch quotes');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuotes(currentSearch, page);
  }, [page, currentSearch]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchTerm !== currentSearch) {
      setCurrentSearch(searchTerm);
      setPage(1);
      setQuoteData(null);
    }
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <Container>
      <Row className="mb-4 align-items-center">
        <Col xs={12} md={6} className="mb-3 mb-md-0">
          <h2 className="quote-list-title">Quotes from a Gr..., well, a Man</h2>
        </Col>
        <Col xs={12} md={6}>
          <Form onSubmit={handleSearch}>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Search quotes..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <Button variant="primary" type="submit">
                <Search size={20} />
              </Button>
            </InputGroup>
          </Form>
        </Col>
      </Row>
      <Row>
        {quoteData?.items.map((quote, index) => (
          (quote.summary || quote.summary !== '') && (
            <Col 
              key={quote.id} 
              lg={6} 
              className="mb-4" 
              ref={index === quoteData.items.length - 1 ? lastQuoteElementRef : null}
            >
              <QuoteItem quote={quote} />
            </Col>
          )
        ))}
      </Row>
      {loading && <div className="loading-indicator">Loading more quotes...</div>}
    </Container>
  );
};

export default QuoteList;