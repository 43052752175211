import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import NavBar from './components/NavBar';
import QuoteList from './components/QuoteList';
import './App.css';
import About from './components/About';

// Component for the About page
const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Container className="mt-4">
          <Routes>
            <Route path="/" element={
              <>
                <QuoteList />
              </>
            } />
            <Route path="/about" element={<About />} />
          </Routes>
        </Container>
      </div>
    </Router>
  );
};

export default App;